import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import DialogHeader from "app/components/common/DialogHeader";
import PreviewDownloadImage from "app/components/common/PreviewDownloadImage";
import TextFieldLabel from "app/components/common/TextFieldLabel";
import { rejectSMStemplate } from "app/services/sms/orgdlt.service";
import React from "react";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import styled from "styled-components";
import DeleteConfirmationDialog from "../DeleteConfirmationDialog";

const ShowDataGrid = ({ title, data }) => {
  return (
    <>
      <Grid container spacing={1} sx={{ pt: 1 }}>
        <Grid item xs={4}>
          <TextFieldLabel title={title} />
        </Grid>
        <Grid item xs={8}>
          <Typography sx={{ fontSize: "16px", fontWeight: 600 }}>
            {data}
          </Typography>
        </Grid>
      </Grid>
      <Divider
        sx={{
          backgroundColor: (theme) => theme.palette.primary.main,
          opcity: 0.5,
        }}
      />
    </>
  );
};

const ViewSmsTemplate = ({
  open = false,
  onClose = () => {},
  entityName = "",
  viewData = {},
  handleModify = () => {},
  handleRefresh = () => {},
}) => {
  const [openDelete, setOpenDelete] = React.useState(false);
  const handleDeleteOpen = () => {
    setOpenDelete(true);
  };
  const handleDeleteClose = () => {
    setOpenDelete(false);
  };
  const handleDelete = () => {
    handleReject();
    handleDeleteClose();
  };
  const {
    mutate: rejectSMStemplateMutate,
    isLoading: rejectSMSTempalteLoading,
  } = useMutation(rejectSMStemplate, {
    onSuccess: (success) => {
      handleRefresh();
      onClose();
      toast.success(success?.data?.message ?? "Template Rejected Successfully");
    },
    onError: (error) => {
      toast.error(error?.response?.data?.message ?? "Error");
    },
  });

  const handleReject = () => {
    rejectSMStemplateMutate({
      tempalteDocId: viewData?._id,
    });
  };
  return (
    <>
      {/*View Template */}

      <DeleteConfirmationDialog
        open={openDelete}
        handleDelete={handleDelete}
        loading={rejectSMSTempalteLoading}
        onClose={handleDeleteClose}
        tittle={`reject ${viewData?.templateType} template`}
      />

      <Dialog
        open={open}
        onClose={onClose}
        PaperProps={{
          sx: { minWidth: "500px" },
        }}
      >
        <DialogTitle sx={{ p: "0 !important" }}>
          <DialogHeader title="Content Template" handleClose={onClose} />
        </DialogTitle>
        <DialogContent sx={{ mt: 1 }}>
          <DialogWrapper>
            <Box className="entity_name__box">
              <Typography className="typo">
                Entity Name : {entityName}
              </Typography>
            </Box>
            <ShowDataGrid title="Sender ID" data={viewData?.senderId} />
            <ShowDataGrid
              title="Template ID"
              data={viewData?.contentTemplateID}
            />
            <ShowDataGrid title="Template Type" data={viewData?.templateType} />
            <ShowDataGrid title="Message" data={viewData?.smsTemplate} />
            <ShowDataGrid
              title="Created By"
              data={viewData?.createdBy?.personName}
            />

            <Box sx={{ mt: 1.5 }}>
              <TextFieldLabel title="Approved Screenshot" />
              <Stack direction="row" alignItems="center" gap={2} sx={{ pt: 1 }}>
                {viewData?.approvedSenderIdScreenshot && (
                  <PreviewDownloadImage
                    src={viewData?.approvedSenderIdScreenshot}
                  />
                )}
              </Stack>
            </Box>
          </DialogWrapper>
        </DialogContent>

        <DialogActions>
          <LoadingButton
            size="large"
            color="error"
            variant="contained"
            onClick={handleDeleteOpen}
            loading={rejectSMSTempalteLoading}
            disabled={
              viewData?.status === "APPROVED" || viewData?.status === "REJECTED"
            }
          >
            {viewData?.status === "REJECTED"
              ? "Already Rejected"
              : rejectSMSTempalteLoading
              ? "Rejecting..."
              : "Reject"}
          </LoadingButton>

          <Button
            variant="contained"
            color="success"
            onClick={handleModify}
            sx={{ mr: 2 }}
          >
            Modify
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ViewSmsTemplate;

const DialogWrapper = styled.div`
  .entity_name__box {
    background-color: rgba(43, 81, 177, 0.05);
    border: 1px solid rgba(27, 50, 60, 0.05);
    border-radius: 10px;
    padding: 10px;
    .typo {
      font-weight: 500;
      font-size: 16px;
      color: #0c2f49;
    }
  }
`;
